<template>
	<div class="total drag" v-draw id="RqParameter5">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="zhuangshizb"></div>
				<div class="zhuangshizj">{{projectData.project}}_参数5</div>
				<div class="zhuangshiyb" @click="closeCompon">
					<div class="icon"></div>
				</div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				煤气回路优化控制模型
			</div>
		</div>
		<!-- 内容 -->
		<div class="content flex">
			<div class="one">
				<div class="flex">
					<div class="word1">主汽压力控制</div>
					<div class="button1">
						<div class="button1_word">控制方式</div>
					</div>
				</div>
				<div class="shadow shadow1">
					<div class="flex">
						<div class="column1">A01-QYSPH</div>
						<div class="column2" @click="toIpt(infoList.MCRANQI.QYSP_H,'A01-QYSPH','QYSP_H','MCRANQI')">
							{{infoList.MCRANQI.QYSP_H}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">A01-QYSPL</div>
						<div class="column2" @click="toIpt(infoList.MCRANQI.QYSP_L,'A01-QYSPL','QYSP_L','MCRANQI')">
							{{infoList.MCRANQI.QYSP_L}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float1 flex">
					<div class="column1">A01-QYSP</div>
					<div class="column2" @click="toIpt(infoList.MCRANQI.QYSP,'A01-QYSP','QYSP','MCRANQI')">
						{{infoList.MCRANQI.QYSP}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A01-DETQY</div>
					<div class="column2" @click="toIpt(infoList.MCRANQI.DETQYSP,'A01-DETQY','DETQYSP','MCRANQI')">
						{{infoList.MCRANQI.DETQYSP}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A01-QYAV</div>
					<div class="column3" @click="toCompon(2,'QYPV','MCRANQI','QYPV_NG3','主汽压力测量值')"
						@dblclick="Cclick(infoList.MCRANQI.QYPV,'QYPV','MCRANQI','QYPV_NG3')">
						{{infoList.MCRANQI.QYPV}}
					</div>
				</div>
				<div class="word2">煤气总管压力控制</div>
				<div class="shadow shadow1">
					<div class="flex">
						<div class="column1">A02-GLPSPH</div>
						<div class="column2" @click="toIpt(infoList.MCSPCL.GLP_H,'A02-GLPSPH','GLP_H','MCSPCL')">
							{{infoList.MCSPCL.GLP_H}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">A02-GLPSPL</div>
						<div class="column2" @click="toIpt(infoList.MCSPCL.GLP_L,'A02-GLPSPL','GLP_L','MCSPCL')">
							{{infoList.MCSPCL.GLP_L}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A02-GLPSP</div>
					<div class="column3" @click="toCompon(2,'SP','MCXK__p__XK12','XK12_SP_NG3','煤气母管压力先控调节')"
						@dblclick="Cclick(infoList.MCXK__p__XK12.SP,'SP','MCXK__p__XK12','XK12_SP_NG3')">
						{{infoList.MCXK__p__XK12.SP}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A02-GLPTC</div>
					<div class="column2" @click="toIpt(infoList.MCRANQI.GLPTC,'A02-GLPTC','GLPTC','MCRANQI')">
						{{infoList.MCRANQI.GLPTC}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A02-GLPPV</div>
					<div class="column3"
						@click="toCompon(2,'YHZL','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','YHMQL_NG3','优化煤气量')"
						@dblclick="Cclick(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL,'YHZL','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','YHMQL_NG3')">
						{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL}}
					</div>
				</div>
				<div class="shadow shadow3 float2">
					<div class="flex">
						<div>
							<div class="flex">
								<div class="column1">A03-TOP</div>
								<div class="column2"
									@click="toIpt(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP,'A03-TOP','TOP','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-SOP</div>
								<div class="column2"
									@click="toIpt(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP,'A03-SOP','SOP','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-EOP</div>
								<div class="column2"
									@click="toIpt(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP,'A03-EOP','EOP','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-TC(m)</div>
								<div class="column2"
									@click="toIpt(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG,'A03-TC(m)','YHJG','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">煤T</div>
								<div class="column3"
									@click="toCompon(2,'YHJGSYSJ','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','SYSJ1_NG3','煤气优化剩余时间')"
									@dblclick="Cclick(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ,'YHJGSYSJ','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','SYSJ1_NG3')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ}}
								</div>
							</div>
						</div>
						<div>
							<div class="flex">
								<div class="column1">A03-OH</div>
								<div class="column2"
									@click="toIpt(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H,'A03-TOP','YHZL_H','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-OL</div>
								<div class="column2"
									@click="toIpt(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L,'A03-TOP','YHZL_L','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-OT</div>
								<div class="column3"
									@click="toCompon(2,'YHZL','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','YHMQL_NG3','优化煤气量')"
									@dblclick="Cclick(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL,'YHZL','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','YHMQL_NG3')">
									{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float3 flex">
					<div class="column1">A04-FYH</div>
					<div class="column2"
						@click="toIpt(infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L,'A03-TOP','YHZL_L','BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
						{{infoList.BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A04-FYPV</div>
					<div class="column3" @click="toCompon(2,'FYPV','MCYINFENG','FYPV_NG3','负压测量值')"
						@dblclick="Cclick(infoList.MCYINFENG.FYPV,'FYPV','MCYINFENG','FYPV_NG3')">
						{{infoList.MCYINFENG.FYPV}}
					</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A06-02LSP</div>
					<div class="column2" @click="toIpt(infoList.MCSONGFENG.O2PV,'A06-02LSP','O2PV','MCSONGFENG')">
						{{infoList.MCSONGFENG.O2PV}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A06-02LPV</div>
					<div class="column3" @click="toCompon(2,'O2PV','MCSONGFENG','O2PV_NG3','氧量设定值')"
						@dblclick="Cclick(infoList.MCSONGFENG.O2PV,'O2PV','MCSONGFENG','O2PV_NG3')">
						{{infoList.MCSONGFENG.O2PV}}
					</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A07-YDSP</div>
					<div class="column3" @click="toCompon(2,'SP','MCRSF__p__RSF2','RSF2_SP_NG3','多炉负荷协调软伺服')"
						@dblclick="Cclick(infoList.MCRSF__p__RSF2.SP,'SP','MCRSF__p__RSF2','RSF2_SP_NG3')">
						{{infoList.MCRSF__p__RSF2.SP}}
					</div>
				</div>
				<div class="button2">
					<div class="button2_word">机炉协调启动</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A06-02LPV</div>
					<div class="column3" @click="toIpt(infoList.MCRANQI.QYSP_L,'A06-02LPV','QYSP_L','MCRANQI')">
						{{infoList.MCRANQI.QYSP_L}}
					</div>
				</div>
				<div class="shadow shadow2 float4 flex">
					<div class="column1">A07-YDSP</div>
					<div class="column3" @click="toCompon(2,'QYPV','MCRANQI','QYPV_NG3','主汽压力测量值')"
						@dblclick="Cclick(infoList.MCRANQI.QYPV,'QYPV','MCRANQI','QYPV_NG3')">
						{{infoList.MCRANQI.QYPV}}
					</div>
				</div>
			</div>
			<div class="two flex">
				<div>
					<div class="flex">
						<div class="buttonx botton3" :style="{
								background:infoList.MCXK__p__XK11 && !infoList.MCXK__p__XK11.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK11
								? toDetail(1, 'XK11', 'MCXK__p__XK11', '', '煤气回路优化控制模型'): ''">
							X
						</div>
						<div class="shadow shadow4 float5 flex">
							<div class="column1">A01-AV</div>
							<div class="column3" @click="toCompon(2,'AV','MCXK__p__XK11','XK11_AV_NG3','主汽压力先控调节')"
								@dblclick="Cclick(infoList.MCXK__p__XK11.AV,'A01-AV','AV','MCXK__p__XK11')">
								{{infoList.MCXK__p__XK11.AV}}
							</div>
						</div>
					</div>
					<div class="shadow shadow5">
						<div class="flex">
							<div class="column1">A05-GLPL</div>
							<div class="column2" @click="toIpt(infoList.MCSPCL.GLP_L,'A05-GLPL','GLP_L','MCSPCL')">
								{{infoList.MCSPCL.GLP_L}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-02PVL</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.O2PVL,'A05-02PVL','O2PVL','MCRANQI')">
								{{infoList.MCRANQI.O2PVL}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FMBL</div>
							<div class="column2"
								@click="toIpt(infoList.MCRANQI.SJFMB_L,'A05-FMBL','SJFMB_L','MCRANQI')">
								{{infoList.MCRANQI.SJFMB_L}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FYH</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.FYPV_H,'A05-FYH','FYPV_H','MCRANQI')">
								{{infoList.MCRANQI.FYPV_H}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FHH</div>
							<div class="column2" @click="toIpt(infoList.MCCYSZ.GLFH_H,'A05-FHH','GLFH_H','MCCYSZ')">
								{{infoList.MCCYSZ.GLFH_H}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-QYH</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.QYSP_H,'A05-QYH','QYSP_H','MCRANQI')">
								{{infoList.MCRANQI.QYSP_H}}
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonx botton4" :style="{
								background:infoList.MCXK__p__XK12 && !infoList.MCXK__p__XK12.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK12
								? toDetail(1, 'XK12', 'MCXK__p__XK12', '', '煤气回路优化控制模型'): ''">
							X
						</div>
						<div class="shadow shadow4 float6 flex">
							<div class="column1">A02-AV</div>
							<div class="column3" @click="toCompon(2,'AV','MCXK__p__XK12','XK12_AV_NG3','煤气母管压力先控调节')"
								@dblclick="Cclick(infoList.MCXK__p__XK12.AV,'AV','MCXK__p__XK12','XK12_AV_NG3')">
								{{infoList.MCXK__p__XK12.AV}}
							</div>
						</div>
					</div>
					<div class="shadow shadow6">
						<div class="flex">
							<div class="column1">A05-QYL</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.QYSP_L,'A05-QYL','QYSP_L','MCRANQI')">
								{{infoList.MCRANQI.QYSP_L}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FHL</div>
							<div class="column2" @click="toIpt(infoList.MCCYSZ.GLFH_L,'A05-FHL','GLFH_L','MCCYSZ')">
								{{infoList.MCCYSZ.GLFH_L}}
							</div>
						</div>
					</div>
					<div class="blue">
						<div class="blue_word">优化启动</div>
					</div>
					<div class="green">
						<div class="green_word">强制优化</div>
					</div>
					<div class="buttonr botton5" :style="{
							background:infoList.MCRSF__P__RSF3 && !infoList.MCRSF__P__RSF3.TS
					        ? '#2AFC30'
					        : 'red',}" @click="infoList.MCRSF__P__RSF3
							    ? toDetail(3,'RSF3','MCRSF__P__RSF3','',''): ''">
						R
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.MCRSF__P__RSF4 && !infoList.MCRSF__P__RSF4.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCRSF__P__RSF4
								    ? toDetail(3,'RSF4','MCRSF__P__RSF4','',''): ''">
							R
						</div>
						<div class="word word3">氧含量过低保护</div>
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.MCRSF__P__RSF5 && !infoList.MCRSF__P__RSF5.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCRSF__P__RSF5
								    ? toDetail(3,'RSF5','MCRSF__P__RSF5','',''): ''">
							R
						</div>
						<div class="word word3">多炉协调</div>
						<div class="button7">
							<div class="button7_word">使能</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.MCQK__p__QK24 && !infoList.MCQK__p__QK24.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK24
								    ? toDetail(2,'QK24','MCQK__p__QK24','',''): ''">
							K
						</div>
						<div class="word word3">高炉煤气压力前馈</div>
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.MCQK__p__QK13 && !infoList.MCQK__p__QK13.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK13
								    ? toDetail(2,'QK13','MCQK__p__QK13','',''): ''">
							K
						</div>
						<div class="word word3">锅炉负荷前馈</div>
					</div>
					<div class="flex">
						<div class="word word4" @click="toCompon(2,'AV','MCRANQI__p__QKMCFOP1','QKMCFOP1_AV_NG3')"
							@dblclick="Cclick(infoList.MCRANQI__p__QKMCFOP1.AV,'AV','MCRANQI__p__QKMCFOP1','QKMCFOP1_AV_NG3')">
							{{infoList.MCRANQI__p__QKMCFOP1.AV}}
						</div>
						<div class="word word5">前馈脉冲重叠</div>
						<div class="button8">
							<div class="button8_word">使能</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonx botton9" :style="{
								background:infoList.MCXK__p__XK11 && !infoList.MCXK__p__XK11.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK11
								? toDetail(1, 'XK11', 'MCXK__p__XK11', '', '送风层操调节回路'): ''">
							X
						</div>
						<div class="buttonx botton10" :style="{
						     	background:infoList.MCMAN__p__MAN27.RM && infoList.MCMAN__p__MAN27.RM == 1
						        ? '#2AFC30'
						        : 'red',
						     	}" @click="infoList.MCMAN__p__MAN27
						     	? toCompon(0,'AV','MCMAN__p__MAN27','MAN27_AV_NG3','功率设定输出手操器','KF_127_B','MCSOUREC','KF_127_NG3'): ''">
							A
						</div>
					</div>
					<div class="shadow shadow7">
						<div class="flex">
							<div class="column1">A11-FDLSP</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.FDLSP,'A11-FDLSP','FDLSP','MCRANQI')">
								{{infoList.MCRANQI.FDLSP}}
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="shadow shadow8 float7 flex">
						<div class="column1">A09-QWPV</div>
						<div class="column3" @click="toCompon(2,'QWPV','MCQIWEN','QWPV_NG3','主汽温度测量值')"
							@dblclick="Cclick(infoList.MCQIWEN.QWPV,'QWPV','MCQIWEN','QWPV_NG3')">
							{{infoList.MCQIWEN.QWPV}}
						</div>
					</div>
					<div class="shadow shadow8 float8 flex">
						<div class="column1">A09-MQWDYZ</div>
						<div class="column2" @click="toIpt(infoList.MCQIWEN.GMQWYZ,'A09-MQWDYZ','GMQWYZ','MCQIWEN')">
							{{infoList.MCQIWEN.GMQWYZ}}
						</div>
					</div>
					<div class="shadow shadow8 float8 flex">
						<div class="column1">A09-QWSP</div>
						<div class="column2" @click="toIpt(infoList.MCQIWEN.QWSP,'A09-QWSP','QWSP','MCQIWEN')">
							{{infoList.MCQIWEN.QWSP}}
						</div>
					</div>
					<div class="shadow shadow8 float9 flex">
						<div class="column1">A08-GLJB</div>
						<div class="column2" @click="toIpt(infoList.MCRANQI.GLJB,'A08-GLJB','GLJB','MCRANQI')">
							{{infoList.MCRANQI.GLJB}}
						</div>
					</div>
					<div class="button11">
						<div class="button11_word">定煤气量控制</div>
					</div>
					<div class="flex">
						<div class="shadow shadow8 float10 flex">
							<div class="column1">A08-ZGLSP</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.GLSP,'A08-ZGLSP','GLSP','MCRANQI')">
								{{infoList.MCRANQI.GLSP}}
							</div>
						</div>
						<div class="buttonr botton12" :style="{
								background:infoList.MCQK__p__QK8 && !infoList.MCQK__p__QK8.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK8
								    ? toDetail(2,'QK8','MCQK__p__QK8','','送风层操调节回路'): ''">
							K
						</div>
					</div>
					<div class="flex">
						<div class="shadow shadow8 float10 flex">
							<div class="column1">A08-ZGLPV</div>
							<div class="column3" @click="toCompon(2,'ZGLPV','MCRANQI','ZGLPV_NG3')"
								@dblclick="Cclick(infoList.MCRANQI.ZGLPV,'ZGLPV','MCRANQI','ZGLPV_NG3')">
								{{infoList.MCRANQI.ZGLPV}}
							</div>
						</div>
						<div class="buttonx botton13" :style="{
								background:infoList.MCXK__p__XK16 && !infoList.MCXK__p__XK16.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK16
								? toDetail(1, 'XK16', 'MCXK__p__XK16', '', ''): ''">
							X
						</div>
					</div>
					<div class="flex">
						<div class="buttonr botton14" :style="{
								background:infoList.MCQK__p__QK6 && !infoList.MCQK__p__QK6.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK6
								    ? toDetail(2,'QK6','MCQK__p__QK6','',''): ''">
							K
						</div>
						<div class="float11">
							<div class="shadow shadow8 flex">
								<div class="column1">A10-GLPV</div>
								<div class="column3" @click="toCompon(2,'GLPV','MCRANQI','GLPV_NG3','高炉煤气流量')"
									@dblclick="Cclick(infoList.MCRANQI.GLPV,'GLPV','MCRANQI','GLPV_NG3')">
									{{infoList.MCRANQI.GLPV}}
								</div>
							</div>
							<div class="shadow shadow8 flex">
								<div class="column1">A10-GLTC</div>
								<div class="column2" @click="toIpt(infoList.MCRANQI.GLTC,'A08-ZGLSP','GLTC','MCRANQI')">
									{{infoList.MCRANQI.GLTC}}
								</div>
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonr botton15" :style="{
								background:infoList.MCQK__p__QK9 && !infoList.MCQK__p__QK9.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK9
								    ? toDetail(2,'QK9','MCQK__p__QK9','',''): ''">
							K
						</div>
						<div class="float12">
							<div class="shadow shadow8 flex">
								<div class="column1">A10-ZLPV</div>
								<div class="column3" @click="toCompon(2,'ZLPV','MCRANQI','ZLPV_NG3','转炉煤气流量')"
									@dblclick="Cclick(infoList.MCRANQI.ZLPV,'ZLPV','MCRANQI','ZLPV_NG3')">
									{{infoList.MCRANQI.ZLPV}}
								</div>
							</div>
							<div class="shadow shadow8 flex">
								<div class="column1">A10-ZLTC</div>
								<div class="column2" @click="toIpt(infoList.MCRANQI.ZLTC,'A08-ZGLSP','ZLTC','MCRANQI')">
									{{infoList.MCRANQI.ZLTC}}
								</div>
							</div>
						</div>
					</div>
					<div class="float13">
						<div class="shadow shadow8 flex">
							<div class="column1">A10-JLPV</div>
							<div class="column3" @click="toCompon(2,'JLPV','MCRANQI','JLPV_NG3','焦炉煤气流量')"
								@dblclick="Cclick(infoList.MCRANQI.JLPV,'JLPV','MCRANQI','JLPV_NG3')">
								{{infoList.MCRANQI.JLPV}}
							</div>
						</div>
						<div class="shadow shadow8 flex">
							<div class="column1">A10-JLTC</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.JLTC,'A08-ZGLSP','JLTC','MCRANQI')">
								{{infoList.MCRANQI.JLTC}}
							</div>
						</div>
					</div>
					<div class="table">
						<div class="line flex">
							<div class="table_column1">压差</div>
							<div class="table_column3">系数</div>
						</div>
						<div class="line flex">
							<div class="table_column1 flex">
								<div class="column1_one"></div>
								<div class="column1_two"> <= </div>
								<div class="column1_three"
									@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__1__rr__,'压差','IN_FD__ll__1__rr__','MCRANQI__p__MQYLFD')">
									{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__1__rr__}}
								</div>
							</div>
								<div class="table_column2"
									@click="toIpt(infoList.MCRANQI__p__MQYLFD.AV_FD__ll__1__rr__,'系数','AV_FD__ll__1__rr__','MCRANQI__p__MQYLFD')">
									{{infoList.MCRANQI__p__MQYLFD.AV_FD__ll__1__rr__}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__1__rr__,'压差','IN_FD__ll__1__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__1__rr__}}
									</div>
									<div class="column1_two">---</div>
									<div class="column1_three"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__2__rr__,'压差','IN_FD__ll__2__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__2__rr__}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MCRANQI__p__MQYLFD.AV_FD__ll__2__rr__,'系数','AV_FD__ll__2__rr__','MCRANQI__p__MQYLFD')">
									{{infoList.MCRANQI__p__MQYLFD.AV_FD__ll__2__rr__}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__2__rr__,'压差','IN_FD__ll__2__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__2__rr__}}
									</div>
									<div class="column1_two">---</div>
									<div class="column1_three"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__3__rr__,'压差','IN_FD__ll__3__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__3__rr__}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MCRANQI__p__MQYLFD.AV_FD__ll__3__rr__,'系数','AV_FD__ll__3__rr__','MCRANQI__p__MQYLFD')">
									{{infoList.MCRANQI__p__MQYLFD.AV_FD__ll__3__rr__}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__3__rr__,'压差','IN_FD__ll__3__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__3__rr__}}
									</div>
									<div class="column1_two">---</div>
									<div class="column1_three"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__4__rr__,'压差','IN_FD__ll__4__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__4__rr__}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MCRANQI__p__MQYLFD.AV_FD__ll__4__rr__,'系数','AV_FD__ll__4__rr__','MCRANQI__p__MQYLFD')">
									{{infoList.MCRANQI__p__MQYLFD.AV_FD__ll__4__rr__}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__4__rr__,'压差','IN_FD__ll__4__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__4__rr__}}
									</div>
									<div class="column1_two">>=</div>
									<div class="column1_three"
										@click="toIpt(infoList.MCRANQI__p__MQYLFD.IN_FD__ll__4__rr__,'压差','IN_FD__ll__4__rr__','MCRANQI__p__MQYLFD')">
										{{infoList.MCRANQI__p__MQYLFD.IN_FD__ll__4__rr__}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MCRANQI__p__MQYLFD.AV_FD__ll__5__rr__,'系数','AV_FD__ll__5__rr__','MCRANQI__p__MQYLFD')">
									{{infoList.MCRANQI__p__MQYLFD.AV_FD__ll__5__rr__}}
								</div>
							</div>
						</div>
						<div class="location">
							<div class="shadow shadow1">
								<div class="flex">
									<div class="column1">A11-FDLSPH</div>
									<div class="column2"
										@click="toIpt(infoList.MCRANQI.FDLSPH,'A11-FDLSPH','FDLSPH','MCRANQI')">
										{{infoList.MCRANQI.FDLSPH}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">A11-FDLSPL</div>
									<div class="column2"
										@click="toIpt(infoList.MCRANQI.FDLSPL,'A11-FDLSPL','FDLSPL','MCRANQI')">
										{{infoList.MCRANQI.FDLSPL}}
									</div>
								</div>
							</div>
							<div class="shadow shadow2">
								<div class="flex">
									<div class="column1">A11-FDLSP</div>
									<div class="column3" @click="toCompon(2,'SP','MCXK__p__XK30','XK30_SP_NG3','发电量调节')"
										@dblclick="Cclick(infoList.MCXK__p__XK30.SP,'SP','MCXK__p__XK30','XK30_SP_NG3')">
										{{infoList.MCXK__p__XK30.SP}}
									</div>
								</div>
							</div>
							<div class="shadow shadow2">
								<div class="flex">
									<div class="column1">A11-FDLTC</div>
									<div class="column2"
										@click="toIpt(infoList.MCRANQI.FDLTC,'A11-FDLTC','FDLTC','MCRANQI')">
										{{infoList.MCRANQI.FDLTC}}
									</div>
								</div>
							</div>
							<div class="shadow shadow2">
								<div class="flex">
									<div class="column1">A11-FDLPV</div>
									<div class="column3" @click="toCompon(2,'FDLPV','MCRANQI','FDLPV_NG3','处理后的发电量测量值')"
										@dblclick="Cclick(infoList.MCRANQI.FDLPV,'FDLPV','MCRANQI','FDLPV_NG3')">
										{{infoList.MCRANQI.FDLPV}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="three">
					<div class="flex">
						<div class="border1">
							<div class="border1_content">软/实际测量</div>
						</div>
						<div class="border2">
							<div class="border2_content" @click="Rccommon = true">高炉煤气软测量</div>
						</div>
					</div>
					<div class="shadow shadow9">
						<div class="flex">
							<div class="column1">煤气流量与煤气设定值对齐等待时间</div>
							<div class="column2"
								@click="toIpt(infoList.MCRANQI.MQLLDQT,'煤气流量与煤气设定值对齐等待时间','MQLLDQT','MCRANQI')">
								{{infoList.MCRANQI.MQLLDQT}}
							</div>
						</div>
					</div>
					<div class="shadow shadow10 float1">
						<div class="flex">
							<div class="column1">A08-GLPZ1</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.GLPZ1,'A08-GLPZ1','GLPZ1','MCRANQI')">
								{{infoList.MCRANQI.GLPZ1}}
							</div>
						</div>
					</div>
					<div class="buttonx botton1" :style="{
						background:infoList.MCXK__p__XK13 && !infoList.MCXK__p__XK13.RM
				        ? '#2AFC30'
				        : 'red',
						}" @click="infoList.MCXK__p__XK13
						? toDetail(1, 'XK13', 'MCXK__p__XK13', '', ''): ''">
						X
					</div>
					<div class="float2 flex">
						<div class="buttonx botton2" :style="{
					     	background:infoList.MCMAN__p__MAN20.RM && infoList.MCMAN__p__MAN20.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN20
					     	? toCompon(0,'AV','MCMAN__p__MAN20','MAN20_AV_NG3','三层高炉煤气手操器','KF_120_B','MCSOUREC','KF_120_NG3'): ''">
							A
						</div>
						<div class="shadow shadow10">
							<div class="flex">
								<div class="column1">A08-A03</div>
								<div class="column2" @click="toCompon(2,'AV','MCMAN__p__MAN20','MAN20_AV_NG3','三层高炉煤气手操器')"
									@dblclick="Cclick(infoList.MCMAN__p__MAN20.AV,'AV','MCMAN__p__MAN20','MAN20_AV_NG3')">
									{{infoList.MCMAN__p__MAN20.AV}}
								</div>
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="shadow shadow10 float3 flex">
							<div class="column1">A08-GLPZ2</div>
							<div class="column2" @click="toIpt(infoList.MCRANQI.GLPZ2,'A08-GLPZ2','GLPZ2','MCRANQI')">
								{{infoList.MCRANQI.GLPZ2}}
							</div>
						</div>
						<div class="table_total">
							<div class="table_title">煤气压力--流量系数</div>
							<div class="table">
								<div class="line flex">
									<div class="table_column1">煤气压力</div>
									<div class="table_column3">系数</div>
								</div>
								<div class="line flex">
									<div class="table_column1 flex">
										<div class="column1_one"></div>
										<div class="column1_two"> <= </div>
										<div class="column1_three"
											@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__1__rr__,'煤气压力','IN_FD__ll__1__rr__','MCRANQI__p__MQYFD')">
											{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__1__rr__}}
										</div>
									</div>
										<div class="table_column2"
											@click="toIpt(infoList.MCRANQI__p__MQYFD.AV_FD__ll__1__rr__,'系数','AV_FD__ll__1__rr__','MCRANQI__p__MQYFD')">
											{{infoList.MCRANQI__p__MQYFD.AV_FD__ll__1__rr__}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__1__rr__,'煤气压力','IN_FD__ll__1__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__1__rr__}}
											</div>
											<div class="column1_two">---</div>
											<div class="column1_three"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__2__rr__,'煤气压力','IN_FD__ll__2__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__2__rr__}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MCRANQI__p__MQYFD.AV_FD__ll__2__rr__,'系数','AV_FD__ll__2__rr__','MCRANQI__p__MQYFD')">
											{{infoList.MCRANQI__p__MQYFD.AV_FD__ll__2__rr__}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__2__rr__,'煤气压力','IN_FD__ll__2__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__2__rr__}}
											</div>
											<div class="column1_two">---</div>
											<div class="column1_three"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__3__rr__,'煤气压力','IN_FD__ll__3__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__3__rr__}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MCRANQI__p__MQYFD.AV_FD__ll__3__rr__,'系数','AV_FD__ll__3__rr__','MCRANQI__p__MQYFD')">
											{{infoList.MCRANQI__p__MQYFD.AV_FD__ll__3__rr__}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__3__rr__,'煤气压力','IN_FD__ll__3__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__3__rr__}}
											</div>
											<div class="column1_two">---</div>
											<div class="column1_three"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__4__rr__,'煤气压力','IN_FD__ll__4__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__4__rr__}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MCRANQI__p__MQYFD.AV_FD__ll__4__rr__,'系数','AV_FD__ll__4__rr__','MCRANQI__p__MQYFD')">
											{{infoList.MCRANQI__p__MQYFD.AV_FD__ll__4__rr__}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__4__rr__,'煤气压力','IN_FD__ll__4__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__4__rr__}}
											</div>
											<div class="column1_two">>=</div>
											<div class="column1_three"
												@click="toIpt(infoList.MCRANQI__p__MQYFD.IN_FD__ll__4__rr__,'煤气压力','IN_FD__ll__4__rr__','MCRANQI__p__MQYFD')">
												{{infoList.MCRANQI__p__MQYFD.IN_FD__ll__4__rr__}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MCRANQI__p__MQYFD.AV_FD__ll__5__rr__,'系数','AV_FD__ll__5__rr__','MCRANQI__p__MQYFD')">
											{{infoList.MCRANQI__p__MQYFD.AV_FD__ll__5__rr__}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex">
							<div class="buttonx botton3" :style="{
					     	background:infoList.MCMAN__p__MAN19.RM && infoList.MCMAN__p__MAN19.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN19
					     	? toCompon(0,'AV','MCMAN__p__MAN19','MAN19_AV_NG3','二层高炉煤气手操器','KF_119_B','MCSOUREC','KF_119_NG3'): ''">
								A
							</div>
							<div class="shadow shadow10 float4">
								<div class="flex">
									<div class="column1">A08-A02</div>
									<div class="column2" @click="toCompon(2,'AV','MCMAN__p__MAN19','MAN19_AV_NG3','二层高炉煤气手操器')"
										@dblclick="Cclick(infoList.MCMAN__p__MAN19.AV,'AV','MCMAN__p__MAN19','MAN19_AV_NG3')">
										{{infoList.MCMAN__p__MAN19.AV}}
									</div>
								</div>
							</div>
						</div>

						<div class="flex">
							<div class="shadow shadow10 float5 flex">
								<div class="column1">A08-GLPZ2</div>
								<div class="column2" @click="toIpt(infoList.MCRANQI.GLPZ3,'A08-A03','GLPZ3','MCRANQI')">
									{{infoList.MCRANQI.GLPZ3}}
								</div>
							</div>
							<div class="table_total">
								<div class="table">
									<div class="line flex">
										<div class="table_column1">阀门开度</div>
										<div class="table_column3">系数</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"></div>
											<div class="column1_two">
												<= </div>
													<div class="column1_three"
														@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__1__rr__,'阀门开度','IN_FD__ll__1__rr__','MCRANQI__p__MQFMFD')">
														{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__1__rr__}}
													</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MCRANQI__p__MQFMFD.AV_FD__ll__1__rr__,'系数','AV_FD__ll__1__rr__','MCRANQI__p__MQFMFD')">
												{{infoList.MCRANQI__p__MQFMFD.AV_FD__ll__1__rr__}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__1__rr__,'阀门开度','IN_FD__ll__1__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__1__rr__}}
												</div>
												<div class="column1_two">---</div>
												<div class="column1_three"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__2__rr__,'阀门开度','IN_FD__ll__2__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__2__rr__}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MCRANQI__p__MQFMFD.AV_FD__ll__2__rr__,'系数','AV_FD__ll__2__rr__','MCRANQI__p__MQFMFD')">
												{{infoList.MCRANQI__p__MQFMFD.AV_FD__ll__2__rr__}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__2__rr__,'阀门开度','IN_FD__ll__2__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__2__rr__}}
												</div>
												<div class="column1_two">---</div>
												<div class="column1_three"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MCRANQI__p__MQFMFD.AV_FD__ll__3__rr__,'系数','AV_FD__ll__3__rr__','MCRANQI__p__MQFMFD')">
												{{infoList.MCRANQI__p__MQFMFD.AV_FD__ll__3__rr__}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__2__rr__,'阀门开度','IN_FD__ll__2__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__2__rr__}}
												</div>
												<div class="column1_two">---</div>
												<div class="column1_three"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MCRANQI__p__MQFMFD.AV_FD__ll__4__rr__,'系数','AV_FD__ll__4__rr__','MCRANQI__p__MQFMFD')">
												{{infoList.MCRANQI__p__MQFMFD.AV_FD__ll__4__rr__}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__}}
												</div>
												<div class="column1_two">>=</div>
												<div class="column1_three"
													@click="toIpt(infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__','MCRANQI__p__MQFMFD')">
													{{infoList.MCRANQI__p__MQFMFD.IN_FD__ll__3__rr__}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MCRANQI__p__MQFMFD.AV_FD__ll__5__rr__,'系数','AV_FD__ll__5__rr__','MCRANQI__p__MQFMFD')">
												{{infoList.MCRANQI__p__MQFMFD.AV_FD__ll__5__rr__}}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="flex">
								<div class="buttonx botton4" :style="{
					     	background:infoList.MCMAN__p__MAN18.RM && infoList.MCMAN__p__MAN18.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN18
					     	? toCompon(0,'AV','MCMAN__p__MAN18','MAN18_AV_NG3','一层高炉煤气手操器','KF_118_B','MCSOUREC','KF_118_NG3'): ''">
									A
								</div>
								<div class="shadow shadow10 float6">
									<div class="flex">
										<div class="column1">A08-A01</div>
										<div class="column2" @click="toCompon(2,'AV','MCMAN__p__MAN18','MAN18_AV_NG3','一层高炉煤气手操器')"
											@dblclick="Cclick(infoList.MCMAN__p__MAN18.AV,'AV','MCMAN__p__MAN18','MAN18_AV_NG3')">
											{{infoList.MCMAN__p__MAN18.AV}}
										</div>
									</div>
								</div>
							</div>
							<div class="location1 flex">
								<div class="buttonx botton5" :style="{
							background:infoList.MCXK__p__XK30 && !infoList.MCXK__p__XK30.RM
					        ? '#2AFC30'
					        : 'red',
							}" @click="infoList.MCXK__p__XK30
							? toDetail(1, 'XK30', 'MCXK__p__XK30', '', ''): ''">
									X
								</div>
								<div class="shadow shadow10 float7">
									<div class="flex">
										<div class="column1">A11-AV</div>
										<div class="column2" @click="toCompon(2,'AV','MCXK__p__XK30','XK30_AV_NG3','发电量调节')"
											@dblclick="Cclick(infoList.MCXK__p__XK30.AV,'AV','MCXK__p__XK30','XK30_AV_NG3')">
											{{infoList.MCXK__p__XK30.AV}}
										</div>
									</div>
								</div>
								<div class="buttonx botton6" :style="{
					     	background:infoList.MCMAN__p__MAN35.RM && infoList.MCMAN__p__MAN35.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN35
					     	? toCompon(0,'AV','MCMAN__p__MAN35','MAN35_AV_NG3','燃气锅炉_MAN','KF_135_B','MCSOUREC','KF_135_NG3'): ''">
									A
								</div>
								<div class="shadow shadow10 float7">
									<div class="flex">
										<div class="column1">A11-A01</div>
										<div class="column2" @click="toCompon(2,'AV','MCMAN__p__MAN18','MAN18_AV_NG3','一层高炉煤气手操器')"
											@dblclick="Cclick(infoList.MCMAN__p__MAN18.AV,'AV','MCMAN__p__MAN18','MAN18_AV_NG3')">
											{{infoList.MCMAN__p__MAN18.AV}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 解释 -->
					<div class="explain">
						<div class="explain_content">
							<div class="explain1">
								说明：1. 主汽压力控制SADD：氧量下限、煤气压力下限、空燃比下限、负压上限、负荷上限、投入自动的阀门、引风机能力达到上限；SSUB: 投入自动的阀门达到下限；
							</div>
							<div class="explain2 flex">
								<div class="blank"></div>
								<div>2. 煤气压力控制SADD：氧量下限、主汽压力上限、空燃比下限、负压上限、负荷上限、投入自动的阀门、引风机能力达到上限；SSUB: 投入自动的阀门达到下限；
								</div>
							</div>
							<div class="explain2 flex">
								<div class="blank"></div>
								<div>3.
									主汽压力在压力控制区间范围内，设定值跟踪测量值；煤气压力在煤气压力控制区间范围内时，设定值跟踪测量值。当煤气阀门达到上限或者下限时，煤气流量设定值对齐煤气量测量值。
								</div>
							</div>
						</div>
					</div>
					<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
					<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node'
						:Lkname='Lkname' :chName="chName" :infoList='infoList'></Historical>
					<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname"
						:node="Manualnode" :Lkname="ManualAname" :titname="Manualtitname" :pmark="Panualmark"
						:pnode="Panualnode" :pnm="Panualnodem" :infoList="infoList">
					</Manual>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Panualmark: '',
				Panualnode: '',
				Panualnodem: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'rqkfirstcontol'
							break
						case 3:
							pathname = 'RqRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Param05', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname, param1, param2, param3) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1)
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname,
								this.Panualmark = param1, this.Panualnode = param2, this.Panualnodem = param3
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.total {
		width: 83vw;
		height: 91vh;
		background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -45.5vh 0 0 -40.6vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.zhuangshizb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-top: 2vh;
		}

		.zhuangshizj {
			width: 19vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 1vw;
		}

		.zhuangshiyb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 1vw;
			margin-top: 2vh;
		}

		.icon {
			width: 2vw;
			height: 3.4vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 28vw;
			margin-top: -0.5vh;
			cursor: pointer;
		}

		.main {
			width: 83vw;
			height: 3vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.content {
			width: 80vw;
			height: 71vh;
			margin-top: -3vh;
			margin-left: 1.5vw;

			.shadow {
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 0vw;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;
				font-family: PingFang-SC-Regular;
				font-size: 1vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;
			}

			.one {
				font-family: PingFang-SC-Regular;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0vh;

				.word1 {
					width: 5vw;
					height: 1vh;
					font-size: 1vh;
					line-height: 2vh;
					color: #d5fffe;
				}

				.button1 {
					width: 4vw;
					height: 2vh;
					background-color: #22fff7;
					border-radius: 0vh;
					text-align: center;
					margin-left: 0.95vw;
					cursor: pointer;

					.button1_word {
						width: 4vw;
						height: 1vh;
						font-size: 1vh;
						line-height: 2vh;
						letter-spacing: 0vh;
						color: #0a4c62;
						text-align: center;
					}
				}

				.shadow1 {
					width: 10vw;
					height: 4vh;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.3vw;
					}

					.column2 {
						width: 4vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}
				}

				.shadow2 {
					width: 10vw;
					height: 2.3vh;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.3vw;
					}

					.column2 {
						width: 4vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 4vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.word2 {
					width: 6vw;
					height: 2vh;
					font-size: 1vh;
					line-height: 2vh;
					color: #d5fffe;
					margin-top: 0vh;
				}

				.shadow3 {
					width: 18vw;
					height: 9.6vh;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.6vw;
					}

					.column2 {
						width: 3vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 3vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.float1 {
					margin-top: 0.7vh;
				}

				.float2 {
					margin-top: 0.4vh;
				}

				.float3 {
					margin-top: 1.5vh;
				}

				.float4 {
					margin-top: 2.2vh;
				}

				.button2 {
					width: 9vw;
					height: 2vh;
					background-color: #22fff7;
					border-radius: 0vh;
					text-align: center;
					margin-top: 13.3vh;
					cursor: pointer;

					.button2_word {
						width: 9vw;
						height: 1vh;
						font-size: 1vh;
						line-height: 2vh;
						letter-spacing: 0vh;
						color: #0a4c62;
						text-align: center;
					}
				}
			}

			.two {
				width: 59.22vw;
				height: 68.33vh;
				background-image: url("~@/assets/images/RqBoiler/para5.png");
				background-size: 100% 100%;
				margin-left: -8vw;
				margin-top: 4vh;

				.buttonx {
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					background-color: #00e4ff;
					color: white;
					cursor: pointer;
				}

				.buttonr {
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					background-color: #04ff57;
					color: white;
					cursor: pointer;
				}

				.shadow4 {
					width: 8vw;
					height: 2vh;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.6vw;
					}

					.column2 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.botton3 {
					margin-top: 2.5vh;
					margin-left: 4.8vw;
					cursor: pointer;
				}

				.float5 {
					margin-top: 2.8vh;
					margin-left: 0.2vw;
				}

				.shadow5 {
					width: 8vw;
					height: 11vh;
					margin-left: 6.3vw;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.6vw;
					}

					.column2 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.botton4 {
					margin-top: 0.6vh;
					margin-left: 4.8vw;
					cursor: pointer;
				}

				.float6 {
					margin-top: 0.8vh;
					margin-left: 0.2vw;
				}

				.shadow6 {
					width: 8vw;
					height: 4vh;
					margin-left: 6.3vw;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.6vw;
					}

					.column2 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}
				}

				.blue {
					width: 4vw;
					height: 2vh;
					background-color: #17b6ff;
					border-radius: 0vh;
					font-size: 1vh;
					line-height: 2vh;
					letter-spacing: 0vh;
					text-align: center;
					margin-top: 0.8vh;
					margin-left: 8.3vw;
					cursor: pointer;

					.blue_word {
						color: #ffffff;
					}
				}

				.green {
					width: 4vw;
					height: 2vh;
					background-color: #04ff57;
					border-radius: 0vh;
					font-size: 1vh;
					line-height: 2vh;
					letter-spacing: 0vh;
					text-align: center;
					margin-top: 0.2vh;
					margin-left: 8.3vw;
					cursor: pointer;

					.green_word {
						color: #0a4c62;
					}
				}

				.botton5 {
					margin-top: 6.6vh;
					margin-left: 4.8vw;
					cursor: pointer;
				}

				.botton6 {
					margin-top: 2.2vh;
					margin-left: 4.8vw;
					cursor: pointer;
				}

				.word {
					width: 6vw;
					height: 1vh;
					font-size: 1vh;
					line-height: 2vh;
					color: #8aeaff;
				}

				.word3 {
					margin-top: 1.3vh;
					margin-left: 4.8vw;
				}

				.button7 {
					width: 3vw;
					height: 2vh;
					border: 1px solid #22fff7;
					box-sizing: border-box;
					text-align: center;
					margin-top: 0.8vh;
					margin-left: 2vw;
					cursor: pointer;

					.button7_word {
						font-size: 1vh;
						line-height: 1.8vh;
						color: #22fff7;
					}
				}

				.word4 {
					width: 2vw;
					height: 1vh;
					font-size: 1vh;
					line-height: 2vh;
					color: #00ffb4;
					margin-top: 1.8vh;
					margin-left: 6.6vw;
				}

				.word5 {
					margin-top: 0.5vh;
					margin-left: 2.2vw;
				}

				.button8 {
					width: 3vw;
					height: 2vh;
					background-color: #22fff7;
					border-radius: 0vh;
					text-align: center;
					margin-left: 2vw;
					cursor: pointer;

					.button8_word {
						font-size: 1vh;
						line-height: 1.8vh;
						color: #0a4c62;
					}
				}

				.botton9 {
					margin-top: 4.2vh;
					margin-left: 14.5vw;
					cursor: pointer;
				}

				.botton10 {
					margin-top: 4.2vh;
					margin-left: 8.8vw;
					cursor: pointer;
				}

				.shadow7 {
					width: 9vw;
					height: 2vh;
					margin-top: 2vh;
					margin-left: 20.8vw;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.6vw;
					}

					.column2 {
						width: 3vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}
				}

				.shadow8 {
					width: 9vw;
					height: 2vh;
					margin-top: 0vh;
					margin-left: 0vw;

					.column1 {
						width: 5.5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.3vw;
					}

					.column2 {
						width: 2.7vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 2.7vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.float7 {
					margin-top: 1.6vh;
					margin-left: 5.5vw;
				}

				.float8 {
					margin-left: 5.5vw;
					margin-top: 0.3vh;
				}

				.float9 {
					margin-left: -4vw;
					margin-top: 1vh;
				}

				.button11 {
					width: 9vw;
					height: 2vh;
					background-color: #22fff7;
					border-radius: 0vh;
					text-align: center;
					margin-left: 1.8vw;
					margin-top: 1vh;
					cursor: pointer;

					.button11_word {
						width: 9vw;
						height: 1vh;
						font-size: 1vh;
						line-height: 2vh;
						letter-spacing: 0vh;
						color: #0a4c62;
						text-align: center;
					}
				}

				.float10 {
					margin-left: 1.8vw;
					margin-top: 0.5vh;
				}

				.botton12 {
					margin-left: 2.5vw;
					cursor: pointer;
				}

				.botton13 {
					margin-left: 2.5vw;
					cursor: pointer;
				}

				.botton14 {
					margin-left: -1vw;
					margin-top: 1.7vh;
					cursor: pointer;
				}

				.float11 {
					margin-left: 1.5vw;
					margin-top: 1vh;
					cursor: pointer;
				}

				.botton15 {
					margin-left: -1vw;
					margin-top: 3.2vh;
					cursor: pointer;
				}

				.float12 {
					margin-left: 1.5vw;
					margin-top: 1vh;
				}

				.float13 {
					margin-left: 1.7vw;
					margin-top: -0.5vh;
				}

				.table {
					width: 10vw;
					height: 14vh;
					font-size: 1vh;
					line-height: 2vh;
					border: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;
					margin-left: 1.2vw;
					margin-top: 3vh;

					.table_column1 {
						width: 7vw;
						height: 2.33vh;
						color: #00e4ff;
						border-bottom: 1px solid rgba(0, 228, 255, .2);
						box-sizing: border-box;
						text-align: center;

						.column1_one {
							width: 2vw;
							color: #00ffb4;
							text-align: right;
							cursor: pointer;
						}

						.column1_two {
							width: 3vw;
							color: #8aeaff;
							text-align: center;
						}

						.column1_three {
							width: 2vw;
							color: #00ffb4;
							text-align: left;
							cursor: pointer;
						}
					}

					.table_column2 {
						width: 3vw;
						color: #00ffb4;
						border-left: 1px solid rgba(0, 228, 255, .2);
						border-bottom: 1px solid rgba(0, 228, 255, .2);
						box-sizing: border-box;
						text-align: center;
						cursor: pointer;
					}

					.table_column3 {
						width: 3vw;
						color: #00e4ff;
						border-left: 1px solid rgba(0, 228, 255, .2);
						border-bottom: 1px solid rgba(0, 228, 255, .2);
						box-sizing: border-box;
						text-align: center;
						cursor: pointer;
					}
				}

				.shadow1 {
					width: 10vw;
					height: 4vh;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.3vw;
					}

					.column2 {
						width: 4vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 4vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.shadow2 {
					width: 10vw;
					height: 2.3vh;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.3vw;
					}

					.column2 {
						width: 4vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 4vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.location {
					margin-left: 1.5vw;
					margin-top: 7.9vh;
				}
			}

			.three {
				.buttonx {
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					background-color: #00e4ff;
					color: white;
					cursor: pointer;
				}

				.border1 {
					width: 5vw;
					height: 2vh;
					border: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;
					margin-top: -2vh;
					margin-left: -2vw;
					cursor: pointer;

					.border1_content {
						font-size: 1vh;
						line-height: 2vh;
						color: #34b3dd;
						text-align: center;
					}
				}

				.border2 {
					width: 7vw;
					height: 2vh;
					background-color: #22afff;
					border-radius: 0vh;
					margin-top: -2vh;
					margin-left: 0.3vw;
					cursor: pointer;

					.border2_content {
						font-size: 1vh;
						line-height: 2vh;
						color: #feffff;
						text-align: center;
					}
				}

				.shadow9 {
					width: 15vw;
					height: 2vh;
					margin-left: -8vw;
					margin-top: 4vh;

					.column1 {
						width: 12vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.3vw;
					}

					.column2 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}
				}

				.shadow10 {
					width: 8vw;
					height: 2vh;

					.column1 {
						width: 5vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 0.3vw;
					}

					.column2 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00ffb4;
						text-align: right;
						cursor: pointer;
					}

					.column3 {
						width: 2vw;
						font-size: 1vh;
						line-height: 1.8vh;
						letter-spacing: 0vh;
						color: #00e4ff;
						text-align: right;
						cursor: pointer;
					}
				}

				.float1 {
					margin-left: -8vw;
				}

				.botton1 {
					margin-left: -9vw;
					margin-top: 2vh;
					cursor: pointer;
				}

				.float2 {
					margin-top: 3.6vh;
				}

				.table_total {
					margin-left: 0.3vw;
					margin-top: 1.5vh;

					.table_title {
						width: 10vw;
						font-size: 1vh;
						line-height: 2vh;
						color: #8aeaff;
						text-align: center;
					}

					.table {
						width: 10vw;
						height: 14vh;
						font-size: 1vh;
						line-height: 2vh;
						border: 1px solid rgba(0, 228, 255, .2);
						box-sizing: border-box;
						margin-left: 0vw;
						margin-top: 0vh;

						.table_column1 {
							width: 7vw;
							height: 2.33vh;
							color: #00e4ff;
							border-bottom: 1px solid rgba(0, 228, 255, .2);
							box-sizing: border-box;
							text-align: center;

							.column1_one {
								width: 2vw;
								color: #00ffb4;
								text-align: right;
								cursor: pointer;
							}

							.column1_two {
								width: 3vw;
								color: #8aeaff;
								text-align: center;
							}

							.column1_three {
								width: 2vw;
								color: #00ffb4;
								text-align: left;
								cursor: pointer;
							}
						}

						.table_column2 {
							width: 3vw;
							color: #00ffb4;
							border-left: 1px solid rgba(0, 228, 255, .2);
							border-bottom: 1px solid rgba(0, 228, 255, .2);
							box-sizing: border-box;
							text-align: center;
							cursor: pointer;
						}

						.table_column3 {
							width: 3vw;
							color: #00e4ff;
							border-left: 1px solid rgba(0, 228, 255, .2);
							border-bottom: 1px solid rgba(0, 228, 255, .2);
							box-sizing: border-box;
							text-align: center;
						}
					}
				}

				.float3 {
					margin-left: -9vw;
					margin-top: 14vh;
				}

				.botton3 {
					margin-top: 0.7vh;
					cursor: pointer;
				}

				.float4 {
					margin-top: 1vh;
				}

				.botton4 {
					margin-top: 2vh;
					cursor: pointer;
				}

				.float5 {
					margin-left: -9vw;
					margin-top: 13vh;
				}

				.float6 {
					margin-top: 2vh;
				}

				.location1 {
					margin-top: 7.4vh;

					.botton5 {
						margin-left: -10.5vw;
					}

					.botton6 {
						margin-left: 1.5vw;
					}
				}
			}
		}

		.explain {
			width: 83vw;
			height: 8vh;
			margin-top: 3vh;
			border-top: 2px solid #bbd6e5;
			font-family: PingFang-SC-Regular;
			font-size: 1vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #4d91a7;

			.explain_content {
				margin-top: 2vh;
				margin-left: 4vw;

				.explain2 {
					.blank {
						width: 2.1vw;
					}
				}
			}
		}
	}
</style>
